<template>
  <div style="margin-top: 20px">
    <!-- 公司普通增值税 -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="公司名称："
        prop="companyName"
        style="width: 300px"
        size="small"
      >
        <el-input v-model="ruleForm.companyName"></el-input>
      </el-form-item>
      <el-form-item
        label="公司税号："
        prop="companyDuty"
        style="width: 300px"
        size="small"
      >
        <el-input v-model="ruleForm.companyDuty"></el-input>
      </el-form-item>
      <el-form-item label="注册地址：" style="width: 300px" size="small">
        <el-input v-model="ruleForm.regAddress"></el-input>
      </el-form-item>
      <el-form-item label="注册电话：" style="width: 300px" size="small">
        <el-input v-model="ruleForm.regPhone"></el-input>
      </el-form-item>
      <el-form-item label="开户银行：" style="width: 300px" size="small">
        <el-input v-model="ruleForm.bankInfo"></el-input>
      </el-form-item>
      <el-form-item label="银行账号：" style="width: 300px" size="small">
        <el-input v-model="ruleForm.bankNo"></el-input>
      </el-form-item>
      <div class="line">
        <div class="text">接收方式</div>
        <div class="titleLine"></div>
      </div>
      <el-form-item
        label="手机号："
        prop="receivePhone"
        style="width: 300px"
        size="small"
      >
        <el-input v-model="ruleForm.receivePhone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：" style="width: 300px" size="small">
        <el-input v-model="ruleForm.receiveMail"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" style="width: 100%" @click="submit"
        >提交并开票</el-button
      >
    </div>
  </div>
</template>
<script>
import AxiosAdapter from '@/request/AxiosAdapter'
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      rules: {
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        companyDuty: [
          { required: true, message: '请输入公司税号', trigger: 'blur' }
        ],
        receivePhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        receiveMail: [
          { required: true, message: '请输入邮箱号码', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    res: {
      type: Object,
      default() {
        return {}
      }
    },
    invoiceInfoId: {
      type: String
    }
  },
  methods: {
    //创建发票
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let invoiceType = null;
           if(this.ruleForm.tabInvoiceType===true){
            invoiceType = this.ruleForm.invoiceType===1?3:4
           }else{
            invoiceType = this.ruleForm.invoiceType
           }
           delete this.ruleForm.invoiceType




          this.ruleForm.invoiceInfoId = this.invoiceInfoId
          const financialInvoiceRiseTo = this.ruleForm
           console.log('公司普通增值税',this.ruleForm)
          //创建发票
          try {
            const res = await AxiosAdapter.post({
              url: '/financialInvoiceRise/createInvoiceRise',
              data: {...financialInvoiceRiseTo,invoiceLocalType:invoiceType,}
            })
            this.ruleForm = {
              companyName: '', //公司名称
              companyDuty: '', //公司税号
              regAddress: '', //注册地址
              regPhone: '', //注册电话
              bankInfo: '', //开户银行
              bankNo: '', //银行账号
              receivePhone: '', //接收人手机号
              receiveMail: '', //接收人邮箱
              userType: '', //用户类型 1个人 2企业
              invoiceType: '', //发票类型1.普票  2.增值税发票
              invoiceInfoId: '' //缴费人id
            }
            this.$refs.ruleForm.clearValidate()
            this.$router.push('/invoicesuccess')
          } catch (error) {
            ElMessage.warning(error)
          }
        }
      })
    }
  },
  computed: {
    ruleForm: {
      get() {
        return this.res
      },
      set() {
        console.log(this.res)
      }
    }
  }
}
</script>

<style>
.line {
  display: flex;
  margin: 30px 0;
}
.text {
  line-height: 22px;
}
.titleLine {
  height: 14px;
  flex: 1;
  margin-left: 20px;
  border-bottom: 2px dashed rgb(188, 188, 188);
}
.btn {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
}
</style>
