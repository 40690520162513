<template>
  <div style="margin-top: 20px">
    <!-- 个人普通增值税发票 -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="90px"
      class="demo-ruleForm"
    >
      <el-form-item label="抬头名称 :" prop="riseName" style="width: 300px">
        <el-input v-model="ruleForm.riseName" size="small"></el-input>
      </el-form-item>
      <div class="line">
        <div class="text">接收方式</div>
        <div class="titleLine"></div>
      </div>
      <el-form-item label="手机号 :" prop="receivePhone" style="width: 300px">
        <el-input v-model="ruleForm.receivePhone" size="small"></el-input>
      </el-form-item>
      <el-form-item label="邮箱 :" style="width: 300px">
        <el-input v-model="ruleForm.receiveMail" size="small"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" style="width: 100%" @click="submit"
        >提交并开票</el-button
      >
    </div>
  </div>
</template>

<script>
import AxiosAdapter from '@/request/AxiosAdapter'
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      rules: {
        riseName: [
          { required: true, message: '请输入抬头名称', trigger: 'blur' }
        ],
        receivePhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        receiveMail: [
          { required: true, message: '请输入邮箱号', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    res: {
      type: Object,
      default() {
        return {}
      }
    },
    invoiceInfoId: {
      type: String
    }
  },
  methods: {
    //创建发票
    submit() {
      ElMessage.warning("只能重新开具一次，谨慎操作")
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.invoiceInfoId = this.invoiceInfoId
          let invoiceType = null;
           if(this.ruleForm.tabInvoiceType===true){
            invoiceType = this.ruleForm.invoiceType===1?3:4
           }else{
            invoiceType = this.ruleForm.invoiceType
           }
           delete this.ruleForm.invoiceType
          


           const financialInvoiceRiseTo = this.ruleForm
          //创建发票
          try {
            const res = await AxiosAdapter.post({
              url: '/financialInvoiceRise/createInvoiceRise',
              data: {...financialInvoiceRiseTo,invoiceLocalType:invoiceType,}
            })
            this.ruleForm = {
              companyName: '', //公司名称
              companyDuty: '', //公司税号
              regAddress: '', //注册地址
              regPhone: '', //注册电话
              bankInfo: '', //开户银行
              bankNo: '', //银行账号
              receivePhone: '', //接收人手机号
              receiveMail: '', //接收人邮箱
              riseName: '', //抬头名称
              userType: '', //用户类型 1个人 2企业
              invoiceType: '', //发票类型1.普票  2.增值税发票
              invoiceInfoId: '' //缴费人id
            }
            this.$refs.ruleForm.clearValidate()
            this.$router.push('/invoicesuccess')
          } catch (error) {
            ElMessage.warning(error)
          }
        }
      })
    }
  },
  computed: {
    ruleForm: {
      get() {
        return this.res
      },
      set() {
        console.log(this.res)
      }
    }
  }
}
</script>

<style>
.line {
  display: flex;
  margin: 30px 0;
}
.text {
  line-height: 22px;
}
.titleLine {
  height: 14px;
  flex: 1;
  margin-left: 20px;
  border-bottom: 2px dashed rgb(188, 188, 188);
}
.btn {
  width: 100%;
  position: fixed;
  bottom: 0px;
}
</style>
