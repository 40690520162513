<template>
  <div class="invoiceContainer">
    <HeaderTit>填写抬头</HeaderTit>
    <!-- 发票抬头 -->
    <el-tabs v-model="activeName" @tab-click="handleClick" v-if="res.tabInvoiceType!=true">
      <el-tab-pane label="增值税普通发票" name="first">
        <div class="checkedName">
          <el-radio v-model="radio" label="1" @input="checked('1')"
            >个人</el-radio
          >
          <el-radio v-model="radio" label="2" @input="checked('2')"
            >企业/组织</el-radio
          >
          <!-- 个人发票 -->
          <PersonalInvoice
            :invoiceInfoId="invoiceInfoId"
            v-if="radio == '1'"
            :res="res"
          >
          </PersonalInvoice>
          <!--企业/组织 -->
          <GeneralCompanyInvoice
            :invoiceInfoId="invoiceInfoId"
            v-if="radio == '2'"
            :res="res"
          >
          </GeneralCompanyInvoice>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-model="activeName" @tab-click="handleClick" v-else>
      <el-tab-pane label="数电普通发票" name="first">
        <div class="checkedName">
          <el-radio v-model="radio" label="1" @input="checked('1')"
            >个人</el-radio
          >
          <el-radio v-model="radio" label="2" @input="checked('2')"
            >企业/组织</el-radio
          >
      
          <PersonalInvoice
            :invoiceInfoId="invoiceInfoId"
            v-if="radio == '1'"
            :res="res"
          >
          </PersonalInvoice>

          <GeneralCompanyInvoice
            :invoiceInfoId="invoiceInfoId"
            v-if="radio == '2'"
            :res="res"
          >
          </GeneralCompanyInvoice>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import HeaderTit from '@/components/HeaderTit.vue'
import PersonalInvoice from '../components/PersonalInvoice.vue'
import GeneralCompanyInvoice from '../components/GeneralCompanyInvoice.vue'
// import VatInvoice from '../components/VatInvoice.vue'
import AxiosAdapter from '@/request/AxiosAdapter'
export default {
  name: 'Invoice',
  components: {
    HeaderTit,
    PersonalInvoice,
    GeneralCompanyInvoice,
    // VatInvoice
  },
  data() {
    return {
      activeName: 'first',
      radio: '1',
      invoiceInfoId: '',
      invoiceData: {},
      res: {
        tabInvoiceType:true,
        companyName: '', //公司名称
        companyDuty: '', //公司税号
        regAddress: '', //注册地址
        regPhone: '', //注册电话
        bankInfo: '', //开户银行
        bankNo: '', //银行账号
        receivePhone: '', //接收人手机号
        receiveMail: '', //接收人邮箱
        riseName: '', //抬头名称
        userType: '', //用户类型 1个人 2企业
        invoiceType: '', //发票类型1.普票  2.增值税发票
        invoiceInfoId: '' //缴费人id
      }
    }
  },
  methods: {
    //获取回显数据
    async getList() {
      this.invoiceInfoId = this.$route.query.invoiceInfoId
      const result = await AxiosAdapter.get({
        url: '/financialInvoiceRise/readInvoiceRise',
        data: { invoiceInfoId: this.invoiceInfoId }
      })
      if (result.activeName == 'first' && result.radio == 1) {
        this.res.riseName = result.riseName
        ? result.riseName
        : result.companyName
        this.res.companyName = result.companyName
      } else {
        this.res.companyName = result.companyName
          ? result.companyName
          : result.riseName
        this.res.riseName = result.riseName;
      }
      this.res.tabInvoiceType = result.isAllEleInvoice
      this.res.companyDuty = result.companyDuty
      this.res.regAddress = result.regAddress
      this.res.regPhone = result.regPhone
      this.res.bankInfo = result.bankInfo
      this.res.bankNo = result.bankNo
      this.res.receivePhone = result.receivePhone
      this.res.receiveMail = result.receiveMail
      this.res.userType = result.userType
      this.res.invoiceType = result.invoiceType
      this.isShow()
    },
    // //切换tab
    handleClick(tab, event) {
      if (tab.props.name == 'second') {
        this.res.invoiceType = '2'
        this.activeName = 'second'
      } else {
        this.res.invoiceType = '1'
        this.activeName = 'first'
        this.checked(this.radio)
      }
    },
    //展示的页面
    isShow() {
      if (this.res.invoiceType == '1') {
        //普票个人
        if (this.res.userType == '1') {
          this.activeName = 'first'
          this.radio = '1'
        } else {
          this.activeName = 'first'
          this.radio = '2'
        }
      } else if (this.res.invoiceType == '2') {
        this.activeName = 'second'
      }
    },
    //切换普票个人及企业/组织
    checked(type) {
      if (type == 1) {
        this.radio = '1'
        this.res.userType = '1'
        this.res.riseName = this.res.riseName
          ? this.res.riseName
          : this.res.companyName
      } else {
        this.radio = '2'
        this.res.userType = '2'
        this.res.companyName = this.res.companyName
          ? this.res.companyName
          : this.res.riseName
      }
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style>
.invoiceContainer {
  width: 100%;
  height: 680px;
}
.el-tabs__nav {
  transform: translateX(100px) !important;
}

.el-dialog__footer {
  text-align: center !important;
}
.checkedName {
  margin-left: 15px;
}
.btn {
  width: 100%;
  position: fixed;
  bottom: 0px;
}
</style>
